<template>
    
        <div class="px-3 md:px-0 pt-4">
            <div class="header bg-blue-500 rounded-md px-4 py-3" v-if="kategori != null">
                <div class="flex items-center">
                    <div class="text-white mr-2 cursor-pointer" @click="$router.go(-1)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                        </svg>
                    </div>
                    <div>
                        <h4 class="text-gray-100 font-semibold tracking-wide text-sm capitalize">Modul {{ $route.params.tipe_modul }}</h4>
                        <p class=" font-semibold tracking-tight text-white sm:text-2xl">
                            {{ kategori.judul }}
                        </p>
                    </div>
                </div>
            </div>

            <p class="my-4 text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize">
                Daftar Modul {{ $route.params.tipe_modul }}
            </p>

            <div v-if="modulList.length > 0">
                <router-link :to="{
                    name : 'detailModul',
                    params : {
                        'singkatan_kategori': kategori.singkatan.toLowerCase(),
                        'tipe_modul'        : $route.params.tipe_modul,
                        'id_paket'          : data.id_paket
                    }
                }" class="flex items-center bg-white py-2 px-3 shadow-md rounded-lg my-2 transition-colors hover:border-gray-300"
                v-for="(data, index) in modulList" :key="index">
                    <div class="w-16">
                        <div class="h-16 w-16 bg-yellow-100 rounded-full content-center flex items-center text-center">
                            <img src="../../assets/images/package.svg" class="h-10 w-10 mx-auto"/>
                        </div>
                    </div>
                    <div class="flex-grow ml-3 pt-1 pb-1">
                        <h2 class="font-semibold tracking-tight text-gray-800 text-xl">{{ data.nama_paket }}</h2>

                        <div v-if="data.status_akses == 1">
                            <span class="mr-5 text-gray-700 text-based">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                                <span v-if="data.harga_coret != null" class=" font-bold tracking-wider text-red-500 mr-1"><s>{{ data.harga_coret | moneyFormat }}</s></span>
                                <span class="text-lg font-bold tracking-wider text-yellow-500">{{ data.harga_paket | moneyFormat }}</span>
                            </span>
                        </div>
                        <div v-else>
                            <span class="mr-5 text-gray-700 text-based">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                                <span v-if="data.harga_paket == null">
                                    Gratis
                                </span>
                                <span v-else>
                                    Rp. {{ data.harga_paket | moneyFormat }}
                                </span>
                            </span>
                            <span class="mr-2 text-gray-700 text-based">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                                </svg>
                                {{ data.kuis_count }} Kuis
                            </span>
                        </div>

                    </div>
                </router-link>
            </div>

            <div class="animate-pulse" v-if="isLoadingPaket">
                <div class="flex bg-white py-2 px-3 rounded-md my-2 transition-colors" v-for="i in 3" :key="i">
                    <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                    </div>

                    <div class="flex-grow ml-3 pt-1 pb-1">
                        <div class="h-6 bg-gray-100 rounded"></div>
                        <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                        <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                    </div>
                </div>
            </div>

            <div v-if="!isLoadingPaket && modulList.length == 0" class="text-center w-full mt-6" >
                <div class="w-2/3 px-5 mx-auto">
                    <img src="@/assets/images/cloud-computing.svg" class="w-40 mx-auto" />
                    <p class="mt-1 text-gray-800 font-semibold">Maaf, data masih kosong</p>
                </div>
            </div>
        </div>
</template>

<script>
    import { errorHandler, showErrorNotif } from '../../helpers/Tools';
    export default {
        name: 'ModulByType',
        data(){
            return {
                isLoading     : true,
                isLoadingPaket: true,
                kategori      : null,
                modulList     : [],
                page          : 1,
                isEmpty       : false
            }
        },
        mounted() {
            this.getKategoriDetail();
            this.scroll();
		},
        destroyed() {
            window.onscroll = () => {
            }
        },
		methods : {
            refresh (loaded) {
                this.page = 1;
                this.getModulByKategori();
                loaded('done')
            },  
            scroll() {
                window.onscroll = () => {
                    const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
                    if (bottomOfWindow && this.kategori != null && !this.isEmpty) {
                        this.getModulByKategori()
                    }
                }
            },
            async getKategoriDetail() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getKategoriDetail", this.$route.params.singkatan_kategori)
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.kategori = result.data;
                        setTimeout(() => {
                            this.getModulByKategori();
                        }, 100);
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

            async getModulByKategori() {
                this.isLoadingPaket = true;
                try {
                    let res = await this.$store.dispatch("getModulByKategoryStatusBayar", {
                        id_kategori : this.kategori.id_kategori,
                        status_akses: this.$route.params.tipe_modul,
                        page        : this.page
                    });
                    this.isLoadingPaket = false;
                    let result = res.data;
                    if(result.status == 'success') {
                        if(result.data.data.length == 0) {
                            this.isEmpty = true;
                        }

                        if(this.page == 1){
                            this.modulList = result.data.data;
                        }else{
                            var ids = new Set(this.modulList.map(d => d.id_paket));
                            var merged = [...this.modulList, ...result.data.data.filter(d => !ids.has(d.id_paket))];
                            this.modulList = merged;
                        }
                        this.page++;
                    } else {
                        this.isEmpty = true;
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoadingPaket = false;
                    errorHandler(this, error);
                }
            }
        }
    }
</script>
